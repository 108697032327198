



































import { defineComponent, computed } from '@vue/composition-api'
import InfoModal from '@/components/InfoModal.vue'
import router from '@/router/index'

import { getLeagueType } from '@/views/Programs/Volunteers/ext/routeutils'

export default defineComponent({
  name: 'MessageConfirmationModal',
  props: {
    value: { type: Boolean, required: true },
    scheduledDate: { type: String, required: false, default: '' },
    errorOccurred: { type: Boolean, required: false, default: false },
  },
  components: { InfoModal },
  setup(props, { emit, root }) {
    const show = computed({
      get(): boolean {
        return props.value
      },
      set(v: boolean) {
        emit('input', v)
      },
    })

    const leagueType = computed(() => {
      return getLeagueType(router.currentRoute.fullPath)
    })

    function viewMessageHistory() {
      close()
      root.$nextTick(() => {
        root.$router.push(`/programs/${leagueType.value}/${root.$route.params.id}/communications/history`)
      })
    }
    function viewScheduled() {
      root.$nextTick(() => {
        root.$router.push(`/programs/${leagueType.value}/${root.$route.params.id}/communications/scheduled`)
      })
    }
    function close() {
      show.value = false
      emit('close')
    }
    return { viewMessageHistory, show, close, viewScheduled }
  },
})
