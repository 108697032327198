



















import { defineComponent, ref, computed, PropType, onMounted } from '@vue/composition-api'
import RadioGroupInput from '@/elements/RadioGroupInput.vue'
import uuid from 'uuid'
import { cloneDeep } from 'lodash'

import store from '@/store'
import facilitiesClient from '@/clients/facilitiesClient'
import { LeagueFacility } from '@/GeneratedTypes/LeagueFacility'
import { FacilityEventEnum } from '@/lib/support/models/FacilityAvailability/types'

interface PracticeFacilityType {
  id: number
  description: string
  selected: boolean
}
export default defineComponent({
  name: 'PracticeFacilitySelector',
  props: {
    value: { type: Array as PropType<number[]>, required: false, default: () => [] }, //array of selected facility IDs
    id: { type: String, required: false, default: '' },
  },
  components: { RadioGroupInput },
  setup(props, { emit }) {
    const guid = uuid.v4()
    const elementId = computed(() => props.id || `checkbox-${guid}`)
    const practiceFacilities = ref<PracticeFacilityType[]>([])
    const internalValue = ref<number[]>([])

    onMounted(async () => {
      internalValue.value = cloneDeep(props.value)
      const facilities = ref<LeagueFacility[]>([])
      facilities.value =
        (await facilitiesClient.retrieveAll(
          store.getters.leagueAbstraction.currentItem.upwardLeagueID ?? '',
          false
        )) ?? []
      practiceFacilities.value =
        facilities.value
          .filter((x) => x.availability?.some((a) => a.typeFacilityEventID == FacilityEventEnum.PRACTICES))
          .map((x) => ({
            id: x.facilityID,
            description: x.facilityName,
            selected: internalValue.value.some((s) => s == x.facilityID),
          })) ?? []
    })

    function handleChange(value: number) {
      const index = internalValue.value.indexOf(value)

      /* This filter takes care of multiple values appearing in the array like ['1','1','2']   */
      if (index !== undefined && index >= 0) {
        internalValue.value = internalValue.value.filter((x) => x !== value)
      } else {
        internalValue.value.push(value)
      }
      emit('input', internalValue.value)
      emit('change', internalValue.value)
    }

    return {
      elementId,
      practiceFacilities,
      handleChange,
    }
  },
})
