import { PreviewMessage } from '@/models/Communication/PreviewMessage'
import { Recipients, Recipient, getEmptyRecipient } from '@/views/Programs/Communications/model/Recipients'
import { EmailCompose } from '@/views/Programs/Communications/model/EmailCompose'
import uuid from 'uuid'
import { PartnerChallengeParticipantInfo } from '@/models/UpwardRunning/PartnerChallengeParticipantInfo'

//Used with recreational league; get the list of recipients.
export function mergeAllRecipients(preview: PreviewMessage) {
  if (preview && preview.to) {
    const additionalEmailArray = preview.toDelimited != '' ? preview.toDelimited.split(', ') : []
    return [...additionalEmailArray, ...preview.to]
  }
  return []
}

//Used with running challenge; get all email addresses from the compose object.
export function getAllEmailAddresses(compose: EmailCompose): string[] {
  const emailAddresses: string[] = []

  // Add emails from toDelimited array
  if (compose.recipients.toDelimited) {
    emailAddresses.push(...compose.recipients.toDelimited.map((email) => email.trim()))
  }

  // Add emails from recipients array
  if (compose.recipients.recipients) {
    emailAddresses.push(
      ...compose.recipients.recipients
        .filter((recipient) => recipient.isSelected)
        .map((recipient) => recipient.email)
    )
  }

  return emailAddresses
}

export function createRecipientsFromChallengeParticipants(
  participants: PartnerChallengeParticipantInfo[]
): Recipient[] {
  if (!participants?.length) {
    return []
  }

  return participants.map((participant) => {
    const recipient = getEmptyRecipient()
    recipient.id = uuid.v4()
    recipient.name = participant.formattedName ?? ''
    recipient.email = participant.emailAddress ?? ''
    recipient.phone = participant.phoneNumber ?? ''
    recipient.isSelected = false
    return recipient
  })
}

export function mergeAndCreateRecipients(preview: PreviewMessage) {
  if (preview && preview.to) {
    //convert the toDelimited string to an array
    const toDelimitedArray = preview.toDelimited != '' ? preview.toDelimited.split(', ') : []
    //combine the toDelimited array with the to array
    const emails = [...toDelimitedArray, ...preview.to]
    //create an array of recipients
    const allRecipients: Recipient[] = []
    if (emails.length) {
      allRecipients.push(
        ...emails.map((email: string) => {
          const recipient = getEmptyRecipient()
          recipient.id = uuid.v4()
          recipient.email = email
          recipient.name = email
          recipient.isSelected = true
          return recipient
        })
      )
    }
    return allRecipients
  }
  return []
}

// Merge all the recipients from the list with the extra ones that where added manually
// by the user (aka the toDelimited values)
export function mergeAllEmailRecipients(recipients: Recipients): Recipient[] {
  if (!recipients?.recipients) {
    return []
  }

  const allRecipients: Recipient[] = []

  // First add the toDelimited emails addresses
  if (recipients.toDelimited) {
    const additionalEmails = recipients.toDelimited.map((email: string) => email.trim())
    allRecipients.push(
      ...additionalEmails.map((email: string) => {
        const recipient = getEmptyRecipient()
        recipient.id = uuid.v4()
        recipient.email = email
        recipient.name = email
        recipient.isSelected = true
        return recipient
      })
    )
  }

  // Then add the selected recipients
  const selectedRecipients = recipients.recipients.filter((r) => r?.isSelected && r.email)
  allRecipients.push(...selectedRecipients)

  return allRecipients
}
