


























import { defineComponent, computed } from '@vue/composition-api'
import InputLabel from '@/elements/InputLabel.vue'
import MemoInput from '@/elements/MemoInput.vue'
import { Recipient } from '@/views/Programs/Communications/model/Recipients'
import store from '@/store'
import { MessageChannelTypes } from '@/components/Communication/MessageChannelData'
export default defineComponent({
  name: 'RecipientListManager',
  components: { InputLabel, MemoInput },
  props: {
    channel: {
      type: String,
      required: true,
      default: MessageChannelTypes.EMAIL,
    },
  },
  setup(props) {
    // Computed property to maintain a reactive copy of the recipients list
    const recipients = computed(() => store.getters.communications.recipients)

    // Helper function to emit updates to the parent component
    const updateRecipients = (recipients: Recipient[]) => {
      store.dispatch.communications.updateRecipientsAndPreview({ recipients, type: props.channel })
    }

    // Selects all recipients in the list
    const selectAllParticipants = () => {
      const updatedRecipients = recipients.value.map((recipient) => ({
        ...recipient,
        isSelected: isRecipientValid(recipient) ? true : recipient.isSelected,
      }))
      updateRecipients(updatedRecipients)
    }

    // Deselects all recipients in the list
    const removeAllParticipants = () => {
      const updatedRecipients = recipients.value.map((recipient) => ({
        ...recipient,
        isSelected: false,
      }))
      updateRecipients(updatedRecipients)
    }

    // Validates if a recipient has the required contact information for the selected channel
    const isRecipientValid = (recipient: Recipient) => {
      if (props.channel === MessageChannelTypes.SMS && !recipient.phone) {
        return false
      }
      if (props.channel === MessageChannelTypes.EMAIL && !recipient.email) {
        return false
      }
      return true
    }

    // Toggles the selection state of a single recipient
    const toggleRecipient = (recipient: Recipient) => {
      if (!isRecipientValid(recipient)) {
        return
      }

      const updatedRecipients = recipients.value.map((r) => {
        if (r.id === recipient.id) {
          return {
            ...r,
            isSelected: !r.isSelected,
          }
        }
        return r
      })
      updateRecipients(updatedRecipients)
    }

    // Returns CSS classes for recipient pills based on selection state
    const getRecipientClasses = (recipient: Recipient) => ({
      'text-decoration-line-through': !isRecipientValid(recipient),
      'border bg-light': isRecipientValid(recipient) && recipient.isSelected,
    })

    // Returns icon classes based on selection state
    const getIconClasses = (recipient: Recipient) => {
      if (!isRecipientValid(recipient)) {
        return {}
      }
      return {
        'fa-times text-danger': recipient.isSelected,
        'fa-plus text-success': !recipient.isSelected,
      }
    }

    return {
      recipients,
      selectAllParticipants,
      removeAllParticipants,
      toggleRecipient,
      getRecipientClasses,
      getIconClasses,
    }
  },
})
