//logic shared by SMS and Email messages
import { ref, computed } from '@vue/composition-api'
import { cloneDeep } from 'lodash'
import { mergeAllRecipients, getAllEmailAddresses } from '@/views/Programs/Communications/ext/allRecipients'
import { ProgramTypes } from '@/lib/common/ProgramTypes'
import store from '@/store'
import dayjs from 'dayjs'
import { DivisionTeamInfo } from '@/GeneratedTypes/ListInfo/DivisionTeamInfo'
import { UpwardOpportunityTypes } from '@/lib/common/upwardOpportunityTypes'
import { getProperGradeLabel } from '@/services/gradeService'
import { EmailCompose } from '@/views/Programs/Communications/model/EmailCompose'
import { useIsRunningChallenge } from '@/lib/common/programTypeHelpers'
import router from '@/router'

export function messageLogic() {
  const allTeams = ref<DivisionTeamInfo[]>([])
  const isRunningChallenge = computed(() => useIsRunningChallenge().value)
  const errorOccurred = ref(false)
  const showConfirmationModal = ref(false)
  const showFilters = ref(false)
  const loadingRecipients = ref(false)
  const showSendDrop = ref<boolean>(false)
  const showScheduleSendModal = ref<boolean>(false)
  const sendCommunicationOn = ref<Date | null>(null)
  const minutesToAdd = computed((): number | null => {
    if (!sendCommunicationOn.value) return null
    const now = dayjs(new Date(Date.now()))
    const sendOn = dayjs(sendCommunicationOn.value)
    return sendOn.diff(now, 'minute', false)
  })

  const toggleSendDrop = () => {
    showSendDrop.value = !showSendDrop.value
  }
  const sendDropAway = () => {
    showSendDrop.value = false
  }
  const sendCommunicationOnFormatted = computed((): string => {
    return (
      sendCommunicationOn.value?.toLocaleTimeString([], {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: '2-digit',
      }) || ''
    )
  })

  const accountNumber = computed(() => store.getters.authorization.firstAccountNumber)
  const providerInfo = computed(() => store.getters.communications.currentItem)
  const messagePreviewLoading = computed(() => store.getters.communications.messagePreviewLoading)
  const upwardLeagueID = computed(() => store.getters.leagueAbstraction.currentItem.upwardLeagueID) // either the UPWID or the URID
  const hasPartners = computed(() => {
    return (store.getters.leagueAbstraction.currentItem.accounts ?? []).length > 1
  })

  const hasRecipients = computed(() => {
    const recipients = store.getters.communications.hasSelectedRecipients
    if (!recipients) {
      return ''
    }
    return String(recipients)
  })

  const isSending = ref(false)
  async function send(type: 'sendemail' | 'sendsms') {
    isSending.value = true
    errorOccurred.value = false
    try {
      await store.dispatch.communications.sendMessage({
        type: type,
        sendCommunicationOn: sendCommunicationOn.value,
        minutesToAdd: minutesToAdd.value,
      })
    } catch (e) {
      errorOccurred.value = true
    } finally {
      isSending.value = false
      showConfirmationModal.value = true
    }
  }

  const loadRunningRecipients = async (upwardRunningId: string) => {
    loadingRecipients.value = true
    await store.dispatch.communications.setRecipientsFromRunningParticipants({
      upwardRunningId,
    })
    loadingRecipients.value = false
  }

  // Used to disable the send button until after the data
  // is loaded.
  const isSendReady = computed(() => {
    if (isRunningChallenge.value) {
      return !loadingRecipients.value
    } else {
      return !messagePreviewLoading.value
    }
  })

  const previewMessage = computed(() => store.getters.communications.messagePreview)

  // used to get all email when recreational league is in play
  const allRecipients = computed(() => {
    return mergeAllRecipients(cloneDeep(previewMessage.value))
  })

  //used to get all email addresses when running challenge is in play
  function allEmailAddresses(compose: EmailCompose): string[] {
    return getAllEmailAddresses(cloneDeep(compose))
  }

  function filterBuilder(list: string, label: string, value: string | null) {
    return `${list} <span class="text-muted small">${label}:</span> <b class="small font-italic mr-2">${value}</b>`
  }

  const filterList = computed(() => {
    let list = ''
    const f = store.getters.communications.messagePreview
    if (f.paymentStatus != 'All') {
      list = filterBuilder(
        list,
        'payment status',
        store.getters.communications.messagePreview.paymentStatus ?? 'All'
      )
    }

    if (f.evalStatus != 'All') {
      list = filterBuilder(
        list,
        'eval status',
        store.getters.communications.messagePreview.evalStatus ?? 'All'
      )
    }

    if (f.coachTeamStatus != 'All Approved') {
      list = filterBuilder(
        list,
        'coach assignment',
        store.getters.communications.messagePreview.coachTeamStatus ?? 'All'
      )
    }

    if (f.typeProgramID != '') {
      list = filterBuilder(
        list,
        'program',
        ProgramTypes[store.getters.communications.messagePreview.typeProgramID ?? '']
      )
    }

    if (hasPartners.value) {
      if (f.viewAllLeagueContacts) {
        list = filterBuilder(list, 'recipients across <u>all</u> churches', 'yes')
      }
    }

    if (f.startGrade != 'K3' || f.endGrade != '12th') {
      const isByAge = store.getters.leagueAbstraction.isByAge
      list = filterBuilder(
        list,
        isByAge ? 'ages' : 'grades',
        `${getProperGradeLabel(f.startGrade!, isByAge, '')} to ${getProperGradeLabel(
          f.endGrade!,
          isByAge,
          ''
        )}`
      )
    }
    if (f.gender != 'E') {
      list = filterBuilder(list, 'participant gender', f.gender == 'M' ? 'Male' : 'Female')
    }
    if (f.practiceNight && f.practiceNight != 'All') {
      list = filterBuilder(list, 'practice night', f.practiceNight)
    }
    if (f.teams && f.teams.length > 0) {
      const names = f.teams
        .map((t) => allTeams.value.find((at) => at.teamID == t)?.teamName)
        .filter((n) => {
          return !!n
        })
      list = filterBuilder(list, 'teams', names.join(', '))
    }
    return list
  })

  const seasonEndDate = computed(() => {
    switch (store.getters.leagueAbstraction.programType) {
      case UpwardOpportunityTypes.LEAGUE:
        return store.getters.leagues.currentItem.seasonEndDate
      case UpwardOpportunityTypes.CAMP:
        return store.getters.camps.currentItem.campEndDate
      default:
        null
    }
  })

  function close() {
    reset()
  }

  function reset() {
    // First clear the store state
    store.commit.communications.clearMessageInformation()

    // Let the UI update in the next tick to avoid validation issues
    router.push({
      path: router.currentRoute.path,
      query: { t: String(Date.now()) }, // Use 't' instead of 'r' to be more semantic (timestamp)
    })
  }

  return {
    showConfirmationModal,
    showFilters,
    loadingRecipients,
    send,
    isSending,
    allRecipients, //for recreational leagues
    allEmailAddresses, //for running challenges
    previewMessage,
    filterList,
    accountNumber,
    upwardLeagueID,
    providerInfo,
    showSendDrop,
    showScheduleSendModal,
    sendDropAway,
    toggleSendDrop,
    minutesToAdd,
    sendCommunicationOn,
    sendCommunicationOnFormatted,
    allTeams,
    errorOccurred,
    seasonEndDate,
    hasRecipients,
    loadRunningRecipients,
    messagePreviewLoading,
    isSendReady,
    isRunningChallenge,
    close,
  }
}
