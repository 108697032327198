



















































































import { defineComponent, computed, ref, watch } from '@vue/composition-api'
import { cloneDeep, debounce } from 'lodash'

import Modal from '@/components/Modal.vue'
import GradeRangeDropdowns from '@/elements/GradeRangeDropdowns.vue'
import RadioGroupInput from '@/elements/RadioGroupInput.vue'
import MessageProgramSelector from '@/components/Communication/MessageProgramSelector.vue'
import SwitchSlot from '@/elements/SwitchSlot.vue'
import DayOfWeekPicker from '@/elements/DayOfWeekPicker.vue'
import DivisionTeamSelector from '@/views/Programs/Communications/vue/Filters/DivisionTeamSelector.vue'
import PracticeFacilitySelector from '@/elements/PracticeFacilitySelector.vue'

import {
  paymentOptions,
  evalOptions,
  coachAssignOptions,
  multiPartnerOptions,
  genderOptions,
} from '@/views/Programs/Communications/data/filterOptions'
import { days } from '@/lib/support/models/UpwardTypes/UpdwardDayTypeID/days'
import { UpwardOpportunityTypes } from '@/lib/common/upwardOpportunityTypes'
import { MessageChannelTypes } from '@/components/Communication/MessageChannelData'

import store from '@/store'

export default defineComponent({
  name: 'MessageFilterModal',
  props: {
    value: { type: Boolean, required: false },
    channel: {
      type: String,
      default: MessageChannelTypes.EMAIL,
      required: false,
    },
  },
  data() {
    return { paymentOptions, evalOptions, coachAssignOptions, multiPartnerOptions, genderOptions }
  },
  components: {
    Modal,
    GradeRangeDropdowns,
    RadioGroupInput,
    MessageProgramSelector,
    DayOfWeekPicker,
    SwitchSlot,
    DivisionTeamSelector,
    PracticeFacilitySelector,
  },
  setup(props, { emit }) {
    const pymtStatus = computed({
      get(): string {
        return store.getters.communications.messagePreview.paymentStatus ?? 'All'
      },
      set(paymentStatus: string) {
        store.commit.communications.setPaymentStatus({ paymentStatus })
      },
    })

    const isLeague = computed(() => {
      return store.getters.leagueAbstraction.programType == UpwardOpportunityTypes.LEAGUE
    })

    const isByAge = computed(() => store.getters.leagueAbstraction.isByAge)

    const evalStatus = computed({
      get(): string {
        return store.getters.communications.messagePreview.evalStatus ?? 'All'
      },
      set(evalStatus: string) {
        store.commit.communications.setEvalStatus({ evalStatus })
      },
    })

    const typeProgramID = computed({
      get(): string {
        return store.getters.communications.messagePreview.typeProgramID ?? ''
      },
      set(typeProgramID: string) {
        store.commit.communications.setTypeProgramID({ typeProgramID })
      },
    })
    const viewAllLeagueContacts = computed({
      get(): boolean {
        return store.getters.communications.messagePreview.viewAllLeagueContacts ?? false
      },
      set(viewAllLeagueContacts: boolean) {
        store.commit.communications.setViewAllLeagueContacts({ viewAllLeagueContacts })
      },
    })

    const coachTeamStatus = computed({
      get(): string {
        return store.getters.communications.messagePreview.coachTeamStatus ?? 'All'
      },
      set(coachTeamStatus: string) {
        store.commit.communications.setCoachTeamStatus({ coachTeamStatus })
      },
    })

    const practice = computed({
      get(): number | null {
        return (
          days.find((p) => p.name == store.getters.communications.messagePreview.practiceNight)?.value ?? null
        )
      },
      set(v: number | null) {
        const practiceNight = days.find((d) => d.value == v)?.name ?? 'All'
        store.commit.communications.setPracticeNight({ practiceNight })
      },
    })

    const teams = computed({
      get(): number[] {
        return store.getters.communications.messagePreview.teams ?? []
      },
      set(teams: number[]) {
        store.commit.communications.setTeams({ teams })
      },
    })

    const practiceFacilities = computed({
      get(): number[] {
        return store.getters.communications.messagePreview.practiceFacilityIDs ?? []
      },
      set(practiceFacilityIDs: number[]) {
        store.commit.communications.setPracticeFacilityIDs({ practiceFacilityIDs })
      },
    })

    async function filterRecipients() {
      await store.dispatch.communications.retrieveRecipientList({
        communicationType: props.channel as MessageChannelTypes,
      })
    }

    const show = computed({
      get(): boolean {
        return props.value
      },
      set(v: boolean) {
        emit('input', v)
      },
    })

    function close() {
      emit('input', false)
    }

    function setGrades(grades: string[]) {
      store.commit.communications.setStartGrade({ startGrade: grades[0] })
      store.commit.communications.setEndGrade({ endGrade: grades[grades.length - 1] })
    }
    const startGrade = computed(() => store.getters.communications.messagePreview.startGrade ?? 'K3')
    const endGrade = computed(() => store.getters.communications.messagePreview.endGrade ?? '12th')

    const gender = computed({
      get(): string {
        return store.getters.communications.messagePreview.gender ?? 'E'
      },
      set(gender: string) {
        store.commit.communications.setGender({ gender })
      },
    })

    const programs = computed(() => {
      return cloneDeep(store.getters.leagueAbstraction.currentItem.programs) ?? []
    })

    const hasPartners = computed(() => {
      return (store.getters.leagueAbstraction.currentItem.accounts ?? []).length > 1
    })

    const hasCheer = computed(() => {
      return programs.value.length > 1
    })

    const small = computed(() => {
      return !hasCheer.value && !hasPartners.value
    })

    const slots = computed(() => {
      return [
        { text: 'Practice Nights', value: 'nights' },
        { text: 'Division/Teams', value: 'teams' },
        { text: 'Practice Facilities', value: 'practiceFacilities' },
      ].filter((i) => {
        if (!isLeague.value) return i.value === 'teams'
        return true
      })
    })
    const slot = ref('nights')
    function slotChange(v: string) {
      // These filters are mutually exclusive,
      // so clear the filters when the slot changes
      if (v === 'teams') {
        store.commit.communications.setPracticeNight({ practiceNight: 'All' })
        store.commit.communications.setPracticeFacilityIDs({ practiceFacilityIDs: [] })
      } else if (v === 'nights') {
        store.commit.communications.setPracticeFacilityIDs({ practiceFacilityIDs: [] })
        store.commit.communications.setTeams({ teams: [] })
        teams.value = []
      } else if (v === 'practiceFacilities') {
        store.commit.communications.setTeams({ teams: [] })
        teams.value = []
        store.commit.communications.setPracticeNight({ practiceNight: 'All' })
      }
    }

    // Create debounced version of filterRecipients
    const debouncedFilterRecipients = debounce(async () => {
      await filterRecipients()
    }, 300)

    // Watch for changes in filter values
    watch(
      [
        evalStatus,
        typeProgramID,
        viewAllLeagueContacts,
        coachTeamStatus,
        pymtStatus,
        gender,
        startGrade,
        endGrade,
      ],
      async () => {
        await debouncedFilterRecipients()
      },
      { deep: true }
    )

    // Watch practice/teams/facilities separately since they're mutually exclusive
    watch(
      [practice, teams, practiceFacilities],
      async () => {
        await debouncedFilterRecipients()
      },
      { deep: true }
    )

    return {
      show,
      setGrades,
      startGrade,
      endGrade,
      hasCheer,
      hasPartners,
      small,
      pymtStatus,
      evalStatus,
      coachTeamStatus,
      typeProgramID,
      viewAllLeagueContacts,
      slot,
      slots,
      practice,
      teams,
      close,
      isLeague,
      practiceFacilities,
      slotChange,
      gender,
      isByAge,
    }
  },
  get isLeague() {
    const opportunityType = store.getters.leagueAbstraction.programType
    if (opportunityType == UpwardOpportunityTypes.LEAGUE) {
      return true
    }
    return false
  },
})
