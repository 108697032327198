











import { defineComponent, computed } from '@vue/composition-api'
import store from '@/store'

import CheckboxGroupInput from '@/elements/CheckboxGroupInput.vue'
import { getRecipientGroups } from '@/views/Programs/Communications/ext/RecipientGroups'
import { MessageChannelTypes } from '@/components/Communication/MessageChannelData'

export default defineComponent({
  name: 'RecipientGroupInput',
  props: {
    label: { type: String, required: false, default: 'Recipient Groups' },
    channel: {
      type: String,
      default: MessageChannelTypes.EMAIL,
      required: false,
    },
  },
  components: { CheckboxGroupInput },
  setup(props) {
    const recipientGroups = getRecipientGroups()

    const selectedGroups = computed({
      get() {
        return [...store.getters.communications.messagePreview.selectedGroups]
      },
      set(groups: string[]) {
        store.commit.communications.setSelectedGroups({
          groups,
        })
        retrieveMessagePreview()
      },
    })

    //Get the recipients for the selected groups
    const retrieveMessagePreview = async () => {
      try {
        await store.dispatch.communications.retrieveRecipientList({
          communicationType: props.channel as MessageChannelTypes,
        })
      } finally {
      }
    }

    return { selectedGroups, recipientGroups }
  },
})
